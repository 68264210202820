<template>
    <!-- form modal -->

    <div
        v-if="showPaystackSubaccountModal"
        tabindex="0"
        @keydown.esc="closeModal"
  
        class="w-100 center modal pt0-l pt7"
        id="add-product-form"
        :class="showPaystackSubaccountModal && 'modal-target'"
        style="z-index: 999999"
        @click="handleOnClickOutsideModal"
    >
        <div class="w-50-l w-90 pv2 center box-border" style="background: white" ref="bankAccountRef">
            <div class="flex justify-between pa3 box-border-bottom">
                <div class="f3 b">Add Bank Account for Paystack</div>
                <div>
                    <router-link to="#">
                        <img src="../../../assets/images/Close-icon.svg" alt="" @click.prevent="closeModal" />
                    </router-link>
                </div>
            </div>

            <!--      Previously div tag-->
            <form class="entity-form" @submit.prevent="createPaystackSubaccount" ref="formRef">
                <div class="pa3">
                    <div v-if="state.isValidBank" class="alert alert-success">
                        Verification successful. Please verify the account name below and submit to complete.
                        <p>ACCOUNT NAME: <span class="text-danger">{{ state.verifiedBank.account_name }}</span></p>
                    </div>
                    <div class="flex flex-wrap items-end justify-between">
                        <div class="w-100-l w-100 pr2-l pb0-l pb3">
                            <div class="pv2 font-w1">
                                <label for="bankCode">Bank<span class="required">*</span></label>
                            </div>
                            <select  
                                class="w-100-l w-100"
                                v-model="state.bankDetails.bankCode"
                                @change="onChange"
                                required
                            >
                                <option value="">Select Bank</option>
                                <option v-for="bank in state.banks" :key="bank.code" :value="bank.code">{{ bank.name }}</option>
                            </select>
                        </div>
                        <div class="w-100-l w-100 pr2-l pb0-l pb3">
                            <div class="pv2 font-w1">
                                <label for="accountNumber">Account Number<span class="required">*</span></label>
                            </div>
                            <input
                                type="text"
                                placeholder="Account Number"
                                id="accountNumber"
                                v-model="state.bankDetails.accountNumber"
                                @change="onChange"
                                required
                            />
                        </div>
                    </div>
                    
                   <div class="flex flex-wrap justify-between items-end  box-border-top mt3 pt1">
                        <button 
                            type="button"
                            class="btn btn-warning w-40"
                            @click="validateAccount"
                            :disabled="state.disableValidateButton"
                        >
                            {{ state.disableValidateButton ? 'Validating...' : 'Validate' }}
                        </button>
                        <button
                            type="submit"
                            class="w-50"
                            :disabled="state.disableSubmitButton"
                        >
                            {{ state.disableSubmitButton && state.isValidBank ? 'Processing...' : 'Submit' }}
                        </button>
                    </div>
                </div>
            </form>
                    
        </div>
    </div>
</template>

<script>
import { reactive, computed, ref, watch, onMounted } from 'vue'
import { useStore } from 'vuex'

export default {
    name: 'PaystackSubaccountModal',
    props: {
        closeModal: Function,
    },

    setup(props) {
        const bankAccountRef = ref(null)
        const formRef = ref(null)
        const store = useStore()
        const showPaystackSubaccountModal = computed(() => store.state?.Settings?.showPaystackSubaccountModal)

        const initialState = {
            bankDetails: {
                bankCode: '',
                accountNumber: '',
            },
            banks: [],
            isValidBank: false,
            verifiedBank: null,
            disableValidateButton: false,
            disableSubmitButton: true
        }
        const state = reactive(initialState)

        watch(
            () => showPaystackSubaccountModal?.value,
            () => {
                document.body.style.overflow = showPaystackSubaccountModal.value ? 'hidden' : 'auto'
            }
        )

        const closeModal = () => {
            props.closeModal()
        }

        const onChange = () => {
            state.isValidBank = false
            state.disableValidateButton = false
            state.disableSubmitButton = true
        }

        const validateAccount = () => {
            if (state.bankDetails.bankCode === '' || state.bankDetails.accountNumber === '') {
                store.dispatch('Alert/setAlert', {
                        message: `Bank and account number is required!`,
                        status: false
                    })
                return;
            }
            state.disableValidateButton = true
            store.dispatch('Settings/verifyAccountNumber', state.bankDetails).then((response) => {
                if (response.status) {
                    state.disableValidateButton = false
                    state.disableSubmitButton = false
                    state.isValidBank = true
                    state.verifiedBank = response.data
                }
            }).finally(() => {state.disableValidateButton = false})
        }

        const createPaystackSubaccount = () => {
            state.disableSubmitButton = true

            store.dispatch('Settings/createPaystackSubaccount', state.bankDetails).then((resp) => {
                state.disableSubmitButton = false
                closeModal()
            }).finally(() => {
                state.disableSubmitButton = false
            })
            
        }

        const handleOnClickOutsideModal = (e) => {
            if (bankAccountRef?.value && !bankAccountRef?.value.contains(e.target)) {
                closeModal()
            }
        }

        onMounted(() => {
            store.dispatch('Settings/getPaystackBanks', state.bankDetails).then((banks) => {
                state.banks = banks
            })
        })

        return {
            state,
            createPaystackSubaccount,
            showPaystackSubaccountModal,
            formRef,
            bankAccountRef,
            handleOnClickOutsideModal,
            validateAccount,
            onChange
        }
    },
}
</script>

<style>
    .entity-form input[type='radio'] {
        background: unset;
        border: none;
        box-shadow: none;
        padding: unset;
        width: unset;
    }
</style>
